// @codekit-prepend "src/splide.min.js",

$(document).ready(function () {

	function goToByScroll(id, offset, duration) {
		$('html,body').animate({ scrollTop: $(id).offset().top - offset }, duration);
	}

	const heroScroller = setTimeout(function () {
		goToByScroll('#hero_video', 0, 500);
	}, 3000);

	const header = $('header');
	const headerBtn = $('header a.btn');
	const navDots = $('header .nav_dots');
	const navBG = $('header .nav_bg');

	function headerScroll() {
		let scrollTop = $(window).scrollTop();
		if (scrollTop > 50) {
			header.addClass('scrolled');
			clearTimeout(heroScroller);
		} else {
			header.removeClass('scrolled')
		}
		//console.log(scrollTop)
	}

	$(window).on('scroll', headerScroll);
	$(window).on('resize', headerScroll);
	$(window).on('resize', function () {
		headerBtn.attr('data-btn-width', headerBtn.outerWidth());
		setTimeout(function () {
			navDots.css('right', headerBtn.outerWidth() + 10 + 30);
			navBG.css('width', navDots.outerWidth() + headerBtn.outerWidth() + 10 + 60);
		}, 150)
	});

	headerBtn.attr('data-btn-width', headerBtn.outerWidth());
	navDots.css('right', headerBtn.outerWidth() + 10 + 30);
	navBG.css('width', navDots.outerWidth() + headerBtn.outerWidth() + 10 + 60);

	$(document).on('click', '.scrolled .nav_dots', function (e) {
		e.preventDefault();
		header.addClass('mobile_nav_open');
	});

	$(document).on('click', '.mobile_nav_close', function (e) {
		e.preventDefault();
		header.removeClass('mobile_nav_open');
	});

	$(document).on('click', '.menu a, a[href="#enquire"]', function (e) {
		e.preventDefault();
		let href = $(this).attr('href');
		goToByScroll(href, 0, 500);
	});

	$(document).on('click', 'a[href^="#"]', function (e) {
		e.preventDefault();
		let href = $(this).attr('href');
		goToByScroll(href, 0, 500);
	});

	$('[data-popup-post]').on('click', function (e) {

		e.preventDefault();
		let postID = $(this).attr('href');
		$.ajax({
			type: "POST",
			url: WPURLS.ajax_url,
			data: {
				action: "popupPost",
				pid: postID
			},
			beforeSend: function (data) {
				$('.popup_template__inner .popup_template__inner_html').html('');
				$('.popup_template__inner svg#load').addClass('loading');
				$('body').addClass('is_fixed');
				$('.popup_template').addClass('open');
			},
			success: function (response) {
				$('.popup_template__inner svg#load').removeClass('loading');
				setTimeout(function () {
					$('.popup_template__inner .popup_template__inner_html').html(response);

					startPopupListTicker();

				}, 250);
			}
		});

	});

	function startPopupListTicker() {
		let html = $('.popup_template__inner .popup_template__inner_html');
		let list = html.find('.popup_list');
		setInterval(function () {
			let count = list.find('h3').length;
			list.find('h3').each(function (i) {
				//console.log(i)
				let _this = $(this);
				if (_this.hasClass('active')) {
					//console.log(_this.text());
					setTimeout(function () {
						_this.removeClass('active');
						if (i == count - 1) {
							list.find('h3').first().addClass('active');
						} else {
							_this.next().addClass('active');
						}
					}, 500)
				}
			});

		}, 3000);

	}

	$('.popup_template__close').on('click', function (e) {
		e.preventDefault();
		$('.popup_template').removeClass('open');
		$('body').removeClass('is_fixed');
	});

	$('.showcase_list p').on('mouseenter', function (e) {
		e.preventDefault();
		$('.showcase_list p, .showcase_list_images [data-row-index]').removeClass('active')
		$(this).addClass('active');
		const rowIndex = $(this).attr('data-row-index');
		$(`.showcase_list_images [data-row-index="${rowIndex}"]`).addClass('active');
	});

	let splide = new Splide('.splide__presentation', {
		type: 'slide',
		autoWidth: true,
		pagination: false,
		gap: 30,
		arrows: false,
		breakpoints: {
			1024: {
				gap: 20,
			},
		}
	}).mount();

	let btnNext = document.getElementById("next");
	let btnPrev = document.getElementById("prev");
	btnNext.addEventListener('click', e => {
		splide.go('+1')
	});

	btnPrev.addEventListener('click', e => {
		splide.go('-1')
	});

	let splideControlOptions = new Splide('.splide__control_options', {
		type: 'slide',
		autoWidth: true,
		pagination: false,
		gap: 30,
		arrows: false,
		breakpoints: {
			1024: {
				gap: 20,
			},
		}
	}).mount();

	let btnNextControlOptions = document.getElementById("nextControlOptions");
	let btnPrevControlOptions = document.getElementById("prevControlOptions");
	btnNextControlOptions.addEventListener('click', e => {
		splideControlOptions.go('+1')
	})

	btnPrevControlOptions.addEventListener('click', e => {
		splideControlOptions.go('-1')
	})

	let splideAddOns = new Splide('.splide__add_ons', {
		type: 'slide',
		autoWidth: true,
		pagination: false,
		gap: 30,
		arrows: false,
		breakpoints: {
			1024: {
				gap: 20,
			},
		}
	}).mount();

	let btnNextAddOns = document.getElementById("nextAddOns");
	let btnPrevAddOns = document.getElementById("prevAddOns");
	btnNextAddOns.addEventListener('click', e => {
		splideAddOns.go('+1')
	})

	btnPrevAddOns.addEventListener('click', e => {
		splideAddOns.go('-1')
	});

	if ($('.splide__testimonial').length > 0) {

		let splideTestimonials = new Splide('.splide__testimonials', {
			type: 'fade',
			autoplay: true,
			rewind: true,
			autoWidth: false,
			pauseOnHover: false,
			pauseOnFocus: false,
			interval: 6000,
			pagination: false,
			arrows: false,
		}).mount();

	}

	$(window).trigger('resize');

});



function cssVariables() {
	const doc = document.documentElement;

	const winW = window.innerWidth;
	const winGutters = (winW - 1700) / 2;
	const navHeight = jQuery('header nav').outerHeight(true) + 110;
	//console.log(navHeight)
	let splidePlaceholder = 20;

	if (winGutters <= 100) {
		if (winW >= 1920) {
			splidePlaceholder = 100;
		} else if (winW >= 1280) {
			splidePlaceholder = 70 + 15;
		} else if (winW >= 1024) {
			splidePlaceholder = 50 + 15;
		} else {
			splidePlaceholder = 20;
		}
	} else {
		splidePlaceholder = winGutters + 10;
	}
	//console.log(winGutters, splidePlaceholder)

	doc.style.setProperty('--splide-placeholder', `${splidePlaceholder}px`);
	doc.style.setProperty('--nav-height', `${navHeight}px`);
}

window.onload = cssVariables;
window.onresize = cssVariables;
